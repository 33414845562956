import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { UX2 } from '@wsb/guac-widget-core';
import { dataAids } from '../constants/dataAids';
import getAllCaptionText from '../utils/getAllCaptionText';

const CAPTION_LENGTH_TO_COLLAPSE = 400;

const getShortenedCaption = (parsedCaption, caption) => {
  if (parsedCaption) {
    let charCount = 0,
      blockAnchor;
    parsedCaption.blocks.some((block, index) => {
      charCount += block.text.length;
      blockAnchor = index;
      return charCount >= CAPTION_LENGTH_TO_COLLAPSE;
    });
    const modifiedBlocksArray = parsedCaption.blocks.map((block, index) => {
      if (index === blockAnchor) {
        const shortenedCaptionText = block.text.slice(0, -(charCount - CAPTION_LENGTH_TO_COLLAPSE));
        return { ...block, text: shortenedCaptionText };
      } else if (index > blockAnchor) {
        return { ...block, text: '' };
      }
      return block;
    });
    const shortenedCaptionObj = {
      ...parsedCaption,
      blocks: modifiedBlocksArray
    };
    return JSON.stringify(shortenedCaptionObj);
  }
  return caption.slice(0, CAPTION_LENGTH_TO_COLLAPSE);
};

function ExpandableCaption({ alignmentOption, caption, staticContent }) {
  const [captionExpanded, toggleCaptionExpanded] = useState(false);

  let captionText, isCaptionLong;

  const parsedCaption = useMemo(() => {
    try {
      return JSON.parse(caption);
    } catch (e) {
      return null;
    }
  }, [caption]);

  const memoizedShortenedCaption = useMemo(
    () => getShortenedCaption(parsedCaption, caption),
    [parsedCaption, caption]
  );

  if (parsedCaption) {
    captionText = getAllCaptionText(parsedCaption);
    isCaptionLong = captionText.length > CAPTION_LENGTH_TO_COLLAPSE;
  } else {
    isCaptionLong = caption.length > CAPTION_LENGTH_TO_COLLAPSE;
  }

  const shouldCollapseCaption = isCaptionLong && alignmentOption !== 'center';
  const shouldShowFullCaption =
    alignmentOption === 'center' || !isCaptionLong || (isCaptionLong && captionExpanded);

  const captionStyles = {
    textAlign: 'left',
    marginTop: 'xxsmall',
    ['@md']: {
      marginTop: alignmentOption === 'center' ? 'xxsmall' : 0
    }
  };

  return (
    <React.Fragment>
      <UX2.Element.Text
        children={ shouldShowFullCaption ? caption : memoizedShortenedCaption }
        style={ captionStyles }
        data-aid={ dataAids.GALLERY_CAPTION_RENDERED }
        richtext
      />
      { shouldCollapseCaption && (
        <UX2.Element.MoreLink.Expand
          tag='span'
          children={ captionExpanded ? staticContent.showLess : staticContent.showMore }
          onClick={ () => toggleCaptionExpanded(!captionExpanded) }
          expanded={ captionExpanded }
          style={{ marginVertical: 'xsmall' }}
          data-aid={ dataAids.GALLERY_CAPTION_MORE_BUTTON }
        />
      ) }
    </React.Fragment>
  );
}

ExpandableCaption.propTypes = {
  alignmentOption: PropTypes.string,
  caption: PropTypes.string,
  staticContent: PropTypes.object
};

export default ExpandableCaption;
