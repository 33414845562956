import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UX2, constants as coreConstants, utils } from '@wsb/guac-widget-core';
import {
  dataAids,
  getGalleryDataAid,
  getGalleryDataRouteProps
} from '../../common/constants/dataAids';
import wrapWithDeviceDetection from '../../common/wrapWithDeviceDetection';
import ExpandableCaption from '../../common/components/ExpandableCaption';
import GalleryImage from '../../common/components/GalleryImage';

const { getImageDimensions } = utils.imageDimensions;

const IMGS_PER_PAGE = 6;

export default wrapWithDeviceDetection(
  class Gallery7 extends Component {
    constructor() {
      super(...arguments);
      this.state = {
        page: 1
      };
      this.loadMore = this.loadMore.bind(this);
    }

    static get propTypes() {
      return {
        id: PropTypes.string,
        category: PropTypes.string,
        device: PropTypes.string,
        galleryImages: PropTypes.array.isRequired,
        renderAsThumbnail: PropTypes.bool,
        renderMode: PropTypes.string,
        section: PropTypes.string,
        size: PropTypes.string,
        staticContent: PropTypes.object,
        alignmentOption: PropTypes.string,
        enableImageDimension: PropTypes.bool
      };
    }

    loadMore(e) {
      e.stopPropagation();
      this.setState({ page: this.state.page + 1 });
    }

    renderSeeMoreText() {
      const { staticContent } = this.props;
      const styles = { paddingVertical: 'xlarge', textAlign: 'right' };

      return (
        <UX2.Element.Block style={ styles }>
          <UX2.Element.MoreLink.Expand
            tag='span'
            onClick={ this.loadMore }
            data-aid={ dataAids.GALLERY_MORE_BUTTON }
            children={ staticContent.showMore }
            data-edit-interactive={ true }
          />
        </UX2.Element.Block>
      );
    }

    renderPages(shouldRenderShorterPreview = false) {
      const {
        galleryImages,
        device,
        size,
        alignmentOption = 'left',
        renderMode,
        staticContent,
        enableImageDimension
      } = this.props;
      const { page } = this.state;

      const flexDirectionMapping = {
        left: 'row-reverse',
        center: 'column',
        right: 'row'
      };

      const containerStyles = {
        marginBottom: 'small',
        ['@md']: {
          marginBottom: 'small',
          flexDirection: flexDirectionMapping[alignmentOption]
        }
      };

      const mobileCaptionProps = {
        last: device === 'mobile' || size === 'xs' || size === 'sm'
      };

      const IMGS_TO_RENDER = shouldRenderShorterPreview ? 3 : page * IMGS_PER_PAGE;
      const shouldRenderDemoCaption =
        shouldRenderShorterPreview || renderMode === coreConstants.renderModes.EDIT;

      // prevent srcset from sizing the image too small
      const srcsetOptions = {
        outputWidth:
          alignmentOption === 'center'
            ? 1250 // max width on md to xl
            : 1023 // max width on xs, sm
      };

      const visibleImages = galleryImages.slice(0, IMGS_TO_RENDER);
      const hasVisibleCaptions =
        shouldRenderDemoCaption || visibleImages.some(({ caption }) => !!caption);

      return visibleImages.map(({ image, caption, externalLink }, index) => {
        if (shouldRenderDemoCaption && !caption) {
          caption = staticContent[`demoCaption${index + 1}`];
        }
        const imageProps = {
          'imageData': { ...image, ...srcsetOptions, enableImageDimension },
          'data-aid': getGalleryDataAid(index),
          'style': {
            width: getImageDimensions(image).outputWidth || 'auto',
            maxWidth: '100%',
            ...(!hasVisibleCaptions && {
              display: 'block',
              margin: '0 auto'
            })
          }
        };

        return (
          <UX2.Component.Grid bottom={ false } key={ index } style={ containerStyles }>
            <UX2.Component.Grid.Cell
              md={ alignmentOption === 'center' || !hasVisibleCaptions ? 12 : 8 }
              xs={ 12 }
              { ...getGalleryDataRouteProps(index, { isImage: true }) }
            >
              <GalleryImage
                imageProps={ imageProps }
                externalLink={ externalLink }
                background={ false }
              />
            </UX2.Component.Grid.Cell>
            { caption && (
              <UX2.Component.Grid.Cell
                md={ alignmentOption === 'center' || !hasVisibleCaptions ? 12 : 4 }
                xs={ 12 }
                { ...mobileCaptionProps }
                { ...getGalleryDataRouteProps(index, { isImage: false }) }
              >
                <ExpandableCaption
                  alignmentOption={ alignmentOption }
                  caption={ caption }
                  staticContent={ staticContent }
                />
              </UX2.Component.Grid.Cell>
            ) }
          </UX2.Component.Grid>
        );
      });
    }

    render() {
      const { galleryImages, renderMode } = this.props;
      const { page } = this.state;
      const pages = Math.ceil(galleryImages.length / IMGS_PER_PAGE);

      const isLayoutView = renderMode === coreConstants.renderModes.DISPLAY;
      const isAddSectionView = renderMode === coreConstants.renderModes.ADD;
      const shouldRenderShorterPreview = isLayoutView || isAddSectionView;

      return (
        <React.Fragment>
          { galleryImages.length ? this.renderPages(shouldRenderShorterPreview) : null }
          { pages > page && !shouldRenderShorterPreview ? this.renderSeeMoreText() : null }
        </React.Fragment>
      );
    }
  }
);
